import {createStore} from "vuex"

// 创建一个新的 store 实例
const store = createStore({
    state() {
        return {
            activity: {
                id: null, //活动id
                name: "", //活动名称
                content: "", //活动详情
                manager: "", //负责人
                phone: "", //电话
                cost: "", //活动费用
                constDetail: "", //费用明细
                beginTime: null, //报名开始时间
                endTime: null,
                status: null,   //活动状态 0 开启 1 关闭
                createTime: null,
                updateTime: null,
            },
        }
    },
    mutations: {
        setActivity(state, activity) {
            state.activity = activity;
        },
    },
    getters: {
        getActivity(state) {
            return state.activity
        }
    }
})

export default store