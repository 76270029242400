import { createApp } from 'vue'
import App from './App.vue'
import 'vant/lib/index.css';
import router from './router/route';
import VantComponents from './component';

import store from "@/store";

const app = createApp(App);

// 3. 注册你需要的组件
app.use(VantComponents);
app.use(router);
app.use(store)
app.mount('#app')
