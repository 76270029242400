<template>
  <van-swipe :autoplay="3000" lazy-render>
    <van-swipe-item v-for="image in images" :key="image">
      <img  :src="`http://manage.siyuan.press/${image}`" style="width:100%;height: 195px;"/>
    </van-swipe-item>
  </van-swipe>
  <div class="content">
    <!--    <div v-for="(paragraph, index) in paragraphs" :key="index" class="payParagraph">-->
    <!--      {{ paragraph }}-->
    <!--    </div>-->
    <div class="paragraph"  v-html="cost_detail">
    </div>
  </div>
  <template v-if="cost_detail.length === 0">
    <van-empty description="没有内容可显示"/>
  </template>
  <van-submit-bar :price=" Number(cost)*100" button-text="前往支付" class="pay" @submit="onSubmit"
  />
</template>

<script setup>
import {showToast} from 'vant';
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import myAxios from "@/plugins/myAxios";

const route = useRoute();
const router = useRouter();
const activity = JSON.parse(localStorage.getItem("activity"))
const activityId = route.params.activityId
const userId = route.params.userId
const cost_detail = ref('')
const cost = ref('')
const appId = 'wxfa343e820df74c3c'
const prepayId = localStorage.getItem("prepayId")
const timeStamp = Date.now().toString()
const nonceStr = Math.random().toString(36).slice(-6)
const paySign = ref('')
const code = route.query.code
// 调起支付
function onBridgeReady() {
  window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": appId,     //公众号ID，由商户传入
        "timeStamp": timeStamp,     //时间戳，自1970年以来的秒数
        "nonceStr": nonceStr,      //随机串
        "package": `prepay_id=${prepayId}`,
        "signType": "RSA",     //微信签名方式：
        "paySign": paySign.value //微信签名
      },
      async function (res) {
        //支付成功
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          showToast("支付成功")
          localStorage.clear()
          await router.push(
              {
                path: "/",
                query: {
                  activity_id: activityId,
                  user_id: userId
                }
              })
        } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
          //支付取消
          showToast("支付取消")
        } else {
          //支付失败
          showToast("支付失败")
        }
      });
}

const images = ref([])
onMounted(async () => {
  console.log("code",code)
  if ( activity== null || prepayId == null || code ==null || code !== localStorage.getItem("code")) {
    await router.push(
        {
          path: "/",
          query: {
            activity_id: activityId,
            user_id: userId
          }
        })
  }else{
    document.title = activity.name;
    //获取 paySign，调用接口
    const res = await myAxios.post("/signEncrypt", {
      appId: appId,
      timeStamp: timeStamp,
      nonceStr: nonceStr,
      package: `prepay_id=${prepayId}`
    })
    console.log("res",res)
    if(res.success){
      paySign.value = res.data
      console.log("paySign", paySign.value)
      //获取 banner 图
      const imgs = await myAxios.get("/activityBanner")
      images.value = imgs.data
      console.log(images.value)
      cost_detail.value = activity.cost_detail
      cost.value = activity.cost
    }
  }
})
const onSubmit = async () => {
  showToast('前往支付')
  if (typeof WeixinJSBridge == "undefined") {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
      document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
    }
  } else {
    onBridgeReady();
  }
};

</script>

<style>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.van-submit-bar__text {
  text-align: left;
}

.paragraph {
  font-size: 1.828rem;
  line-height: 2.716rem;
  margin-bottom: 0.96rem;
  margin-top: 0.96rem;
  text-align: justify;
  white-space: pre-wrap
}

.content {
  padding-top: 0.96rem;
  width: 29.472rem;
  margin: 0.96rem auto 0;
}

.pay .van-submit-bar__button--danger {
  border-radius: 0;
  width: 14.4rem;
  height: 4.704rem;
  float: right;
  background-color: #f36d6c;
}

.pay .van-submit-bar__bar {
  padding: 0;
}

.van-submit-bar__text > span:first-child {
  display: none; /* 仅隐藏第一个 span（合计:） */
}

.van-submit-bar__price {
  color: #f36d6c;
  background-color: #ffffff;
  padding-left: 0.96rem;
}
</style>