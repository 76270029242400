// 1. 引入你需要的组件
import { Button } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Form, Field, CellGroup ,Picker,Popup,Area,SubmitBar,Empty} from 'vant';

export default {
    install(app) {
        app.use(Button);
        app.use(Swipe);
        app.use(SwipeItem);
        app.use(Form);
        app.use(Picker)
        app.use(Field);
        app.use(CellGroup);
        app.use(Popup)
        app.use(Area)
        app.use(SubmitBar)
        app.use(Empty)
    }
};
