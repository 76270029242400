<template>
 <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
html {
  font-size: calc(100vw / 36); /* 使用 calc() 来计算字体大小 */
  font-size: -webkit-calc(100vw / 36); /* 针对 WebKit 浏览器 */
  font-size: -moz-calc(100vw / 36); /* 针对 Firefox 的浏览器 */
}
html, body {
  height: 100%; /* 使 html 和 body 的高度都为 100% */
  margin: 0; /* 清除默认的 margin */
  padding: 0; /* 清除默认的 padding */
}
</style>
