import {createRouter, createWebHistory} from 'vue-router'

import IndexVue from '../components/IndexVue.vue'
import FormVue from '../components/FormVue.vue'
import PayVue from '../components/PayVue.vue'

const routes = [{path: '/', component: IndexVue, name: "index"}, {
    path: '/form/:activityId/:userId',
    component: FormVue
}, {path: '/pay/:activityId/:userId', component: PayVue},]

const router = createRouter({
    // history: createWebHistory('/static/yanxue'), //发布版本
    // history: createWebHistory('/'), //测试版本
    history: createWebHistory('/'), //测试版本
    routes,
})
export default router;